body {
  font-family: 'Poppins', sans-serif;
  padding-right: 0px !important;
}

a {
  text-decoration: none;
}

/* .medisante-placeholder {
  text-align: center;
  padding: 32px 16px 32px 16px;
  border-radius: 4px;
  background-color: #f5f5f5;
} */

.loadMore {
  display: none;
}

@media screen and (min-height: 2500px) {
  .loadMore {
    display: table-row;
  }
}
